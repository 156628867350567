import request from '@/utils/request'


// 查询常见问题配置列表
export function listProblemMessage(query) {
  return request({
    url: '/platform/problem-message/list',
    method: 'get',
    params: query
  })
}

// 查询常见问题配置分页
export function pageProblemMessage(query) {
  return request({
    url: '/platform/problem-message/page',
    method: 'get',
    params: query
  })
}

// 查询常见问题配置详细
export function getProblemMessage(data) {
  return request({
    url: '/platform/problem-message/detail',
    method: 'get',
    params: data
  })
}

// 新增常见问题配置
export function addProblemMessage(data) {
  return request({
    url: '/platform/problem-message/add',
    method: 'post',
    data: data
  })
}

// 修改常见问题配置
export function updateProblemMessage(data) {
  return request({
    url: '/platform/problem-message/edit',
    method: 'post',
    data: data
  })
}

// 删除常见问题配置
export function delProblemMessage(data) {
  return request({
    url: '/platform/problem-message/delete',
    method: 'post',
    data: data
  })
}
